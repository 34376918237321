import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PortalModule,
  PortalUserService,
  providePortalEnvironment,
} from '@jfw-library/platform/common-components/portal';
import { PlatformServicesModule } from '@jfw-library/platform/services';
import { PhoneFormModule } from '@jfw-library/shared/common-components/form-fields';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailsComponent } from './components/account/details/details.component';
import { ManageV2Component } from './components/account/manage-v2/manage-v2.component';
import { ManageComponent } from './components/account/manage/manage.component';
import { ApproveSuccessComponent } from './components/commission/approve/approve-success/approve-success.component';
import { CommissionApproveComponent } from './components/commission/approve/commission-approve.component';
import { CommissionApprovedComponent } from './components/commission/approved/commission-approved.component';
import { CommissionDetailsComponent } from './components/commission/details/commission-details.component';
import { CommissionGroupDetailComponent } from './components/commission/group-detail/commission-group-detail.component';
import { PaidGroupListingComponent } from './components/commission/paid-group-listing/paid-group-listing.component';
import { PaidGroupStatementComponent } from './components/commission/paid-group-statement/paid-group-statement.component';
import { CommissionSearchComponent } from './components/commission/search/commission-search.component';
import { ConfirmationPageComponent } from './components/confirmation/confirmation.component';
import { TokenInterceptor } from './services/token.interceptor';
//deploy1
function initializeAppFactory(
  portalUserService: PortalUserService,
): () => Promise<void> {
  return () => portalUserService.initializeFirebase(environment.firebaseConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    ApproveSuccessComponent,
    ManageComponent,
    CommissionApproveComponent,
    CommissionApprovedComponent,
    CommissionDetailsComponent,
    CommissionGroupDetailComponent,
    CommissionSearchComponent,
    ConfirmationPageComponent,
    DetailsComponent,
    ManageV2Component,
    PaidGroupListingComponent,
    PaidGroupStatementComponent,
  ],
  imports: [
    PortalModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    PhoneFormModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    PlatformServicesModule.forRoot(environment),
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    providePortalEnvironment(environment),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PortalUserService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
