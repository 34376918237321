import { AccountingPortalEnvironment, Site } from 'common-types';

export const environment: AccountingPortalEnvironment = {
  // required BaseEnvironment properties
  production: true,
  isLocal: false,
  disableConsole: false,
  site: Site.AccountingPortal,
  envVersion: 'prod',
  recaptchaAppCheckKey: '6LeHyzwpAAAAAN6cdJV84UEy57UaFTBt_AKrNU2o', // same as dev key (Accounting Portal Prod AppCheck)
  firebaseConfig: {
    apiKey: 'AIzaSyAMbTpHOzVfQep7rbq_RzcD9M-8gwx7o-w',
    authDomain: 'jfw-platform.firebaseapp.com',
    projectId: 'jfw-platform',
    storageBucket: 'jfw-platform.appspot.com',
    messagingSenderId: '1052913360019',
    appId: '1:1052913360019:web:667d5e6aae65383c148166',
    measurementId: 'G-5ST65GVF85',
  },

  // api url's
  account_rest_api_server_url: '/api/2/account',
  dealerUser_rest_api_server_url: '/api/2/dealerUser',
  order_rest_api_server_url: '/api/1/platform-order',
  commission_rest_api_server_url: '/api/3/commission',

  dealer_prefix_for_email_and_sms_communication: 'https://dealers.jimsfw.com',
  ecom_prefix_for_email_and_sms_communication: 'https://www.jimsformalwear.com',
};
