import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Dealer, DealerUser } from 'common-types';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class DealerUserService {
  private readonly apiUrl = this.environment.dealerUser_rest_api_server_url;
  private readonly reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public dealerUserToManage$: BehaviorSubject<DealerUser | undefined>;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) {
    this.dealerUserToManage$ = new BehaviorSubject<DealerUser | undefined>(
      undefined,
    );
  }

  public setDealerUserToManage(dealerUser: DealerUser) {
    this.dealerUserToManage$.next(dealerUser);
  }

  public clearDealerUserToManage() {
    this.dealerUserToManage$.next(undefined);
  }

  public createUser(user: DealerUser): Observable<DealerUser> {
    const url = `${this.apiUrl}/create`;
    return this.httpClient.post<DealerUser>(url, user, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public markEmailInviteSent(user: DealerUser): Observable<DealerUser> {
    const url = `${this.apiUrl}/email-invite/${user.documentId}`;
    return this.httpClient.put<DealerUser>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public getPendingUsers(): Observable<DealerUser[]> {
    const url = `${this.apiUrl}/pending`;
    return this.httpClient.get<DealerUser[]>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public getUsersByDealerId(dealerId: string): Observable<DealerUser[]> {
    const url = `${this.apiUrl}/dealerId/${dealerId}`;
    return this.httpClient.get<DealerUser[]>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public updateUserRole(
    id: string,
    dealerId: string,
    role: string,
  ): Observable<DealerUser> {
    const url = `${this.apiUrl}/id/${id}/dealerId/${dealerId}/role/${role}`;
    return this.httpClient.post<DealerUser>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public enableDealerUser(firestoreId: string): Observable<void> {
    const url = `${this.apiUrl}/enable/${firestoreId}`;
    return this.httpClient.post<void>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public disableDealerUser(firestoreId: string): Observable<void> {
    const url = `${this.apiUrl}/disable/${firestoreId}`;
    return this.httpClient.delete<void>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public deleteDealerUser(firestoreId: string) {
    const url = `${this.apiUrl}/delete/${firestoreId}`;
    return this.httpClient.delete<void>(url, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public addDealer(dealer: Dealer, documentId: string): Observable<DealerUser> {
    const url = `${this.apiUrl}/addDealer/${documentId}`;
    return this.httpClient.put<DealerUser>(url, dealer, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }

  public removeDealer(
    dealerId: string,
    documentId: string,
  ): Observable<DealerUser> {
    const url = `${this.apiUrl}/removeDealer/${documentId}/${dealerId}`;
    return this.httpClient.put<DealerUser>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }

  public updateDefaultDealer(
    dealerId: string,
    documentId: string,
  ): Observable<DealerUser> {
    const url = `${this.apiUrl}/updateDefaultDealer/${documentId}/${dealerId}`;
    return this.httpClient.put<DealerUser>(
      url,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      },
    );
  }
}
