<mat-toolbar>
  <div class="left-side">
    <button mat-icon-button (click)="onMenuClick()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 routerLink="/home" style="cursor: pointer">{{ portalTitle }}</h1>
  </div>
  <div class="local-site" *ngIf="servedEnvironment === 'local'">Local</div>

  <div class="dev-site" *ngIf="servedEnvironment === 'dev'">Dev</div>

  <div class="test-site" *ngIf="servedEnvironment === 'test'">Test</div>
  <div class="right-side">
    <button mat-icon-button>
      <mat-icon>settings</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="appsMenu">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #appsMenu="matMenu">
      <button mat-menu-item>ADP</button>
      <button mat-menu-item>JFW Sites</button>
      <button mat-menu-item>???</button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="accountMenu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu">
      <button mat-menu-item (click)="onClickSignOutButton()">Sign Out</button>
    </mat-menu>
  </div>
</mat-toolbar>
