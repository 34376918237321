import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { PortalUserService } from '../../services/portal-user/portal-user.service';
import { PORTAL_ENVIRONMENT } from '../../tokens';

@Component({
  selector: 'jfw-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent {
  @Input() portalTitle = '';
  @Output() menuClick = new EventEmitter<void>();

  private environment = inject(PORTAL_ENVIRONMENT);
  public servedEnvironment = this.environment.envVersion;

  constructor(private portalUserService: PortalUserService) {}

  onClickSignOutButton(): void {
    this.portalUserService.signOut();
  }

  onMenuClick(): void {
    this.menuClick.emit();
  }
}
